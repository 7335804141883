//
@import "./styles/variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";

.container {
  max-width: none !important;
}
.tenenv_logo_container {
  position: relative;
}

.main-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.1);
  overflow: auto;
  padding-top: 1rem;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}

.sidebar-menu-collapse.show {
  width: auto;
}

.navbar-fixed-top {
  float: right;
  position: absolute;
  z-index: 0!important;
}

.sidebar-menu-toggle:focus {
  box-shadow: none;
  background-color: #eee;
}

.unique-logins {
  justify-content: right;
  display: flex;
  form {
    background-color: #027dd5;
    padding: 5px 10px;
    border-radius: 5px;
    height: 36px;
    input {
      padding: 0.1em;
    }
    label {
      color: #fff;
    }
  }
}
.title-container {
  margin: 0 2%;
  width:97%;
  display: flex;
}
.tiles-container {
  display: flex;
  justify-content: space-around;
  width: 97%;
  margin: 2%;
  flex-direction: row;
  flex-wrap: wrap;
}
.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  padding: 10px;
  color: #fff;
  margin: 1%;
}
.bg-aqua {
  background-color: #00c0ef !important;
}
.bg-green,
.modal-success .modal-body {
  background-color: #00a65a !important;
}
.bg-yellow,
.modal-warning .modal-body {
  background-color: #f39c12 !important;
}
.bg-red,
.modal-danger .modal-body {
  background-color: #dd4b39 !important;
}
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 97%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin: 2%;

  .box-header {
    &.with-border {
      border-bottom: 1px solid #f4f4f4;
    }

    .box-title {
      display: inline-block;
      font-size: 18px;
      margin: 0;
      line-height: 1;
    }
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
  }
}
.range_inputs {
  padding: 30px 30px 20px 30px;
}
.react-datepicker-wrapper {
  padding: 0px 10px;
}
.select-community {
  padding-top:20px;
}
@media(max-width:1200px) {
  .navbar-fixed-top button {
    width: 200px;
    word-wrap: break-word;
    white-space: break-spaces;
  }
}

@media(max-width:780px) {
  .Dropdown-root {
    margin: auto;
    padding: 20px;
  }
}